import clsx from 'clsx'
import { useRef } from 'react'

import type { AnnouncementDetailModalProps } from './type'

import { DocumentationContent } from '@/components/documentation'
import { Dialog, TextButton } from '@/components/ui'
import { formatDate } from '@/utils/date'

import styles from './style.module.css'

export const AnnouncementDetailModal = ({
  isOpen,
  title,
  announcementDate,
  content,
  onClose,
}: AnnouncementDetailModalProps) => {
  const date = formatDate(announcementDate, 'yyyy-MM-dd')
  const ref = useRef(null)
  return (
    <Dialog
      title="お知らせ"
      isOpen={isOpen}
      initialFocus={ref}
      onClose={onClose}
    >
      <div className={clsx('tw-px-2 tw-pb-2 tw-text-sm')} ref={ref}>
        <div className={clsx('tw-text-gray-600')}>{date}</div>
        <div className={clsx('tw-font-bold')}>{title}</div>
        <div className={clsx('tw-mt-4', styles.cancelContentPaddingX)}>
          <DocumentationContent document={content} />
        </div>
        <div className={clsx('tw-mt-4 tw-flex tw-justify-center')}>
          <TextButton onClick={onClose}>閉じる</TextButton>
        </div>
      </div>
    </Dialog>
  )
}
