import { useQuery } from 'react-query'

import { contentfulPreviewClient } from '@/lib/contentful'
import { QueryConfig } from '@/lib/react-query'
import type { IAnnouncementFields } from '@/types/generated/contentful'

import { contentfulPreviewAnnouncementKeyFactory } from './key'

export const getContentfulAnnouncementPreview = ({
  id,
}: Parameters<
  typeof contentfulPreviewAnnouncementKeyFactory.announcement
>[0]) => {
  return contentfulPreviewClient.getEntry<IAnnouncementFields>(id)
}

export type UseContentfulPreviewAnnouncementOptions = Parameters<
  typeof contentfulPreviewAnnouncementKeyFactory.announcement
>[0] & {
  config?: QueryConfig<typeof getContentfulAnnouncementPreview>
}

export const useContentfulPreviewAnnouncement = ({
  config,
  id,
}: UseContentfulPreviewAnnouncementOptions) => {
  const queryConfig: QueryConfig<typeof getContentfulAnnouncementPreview> = {
    ...config,
    queryKey: contentfulPreviewAnnouncementKeyFactory.announcement({
      id,
    }),
    queryFn: () =>
      getContentfulAnnouncementPreview({
        id,
      }),
  }
  return useQuery(queryConfig)
}
