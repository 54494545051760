import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { orderKeyFactory } from './key'

const getOrderDetail = ({
  pathParams: { orderNo },
}: Parameters<typeof orderKeyFactory.detail>[0]) => {
  return aspidaClient.v1.orders._orderNo(orderNo).$get()
}

export type UseOrderDetailOptions = Parameters<
  typeof orderKeyFactory.detail
>[0] & {
  config?: QueryConfig<typeof getOrderDetail>
}

export const useOrderDetail = ({
  pathParams,
  config,
}: UseOrderDetailOptions) => {
  const queryConfig: QueryConfig<typeof getOrderDetail> = {
    ...config,
    queryKey: orderKeyFactory.detail({ pathParams }),
    queryFn: () => getOrderDetail({ pathParams }),
  }
  return useQuery(queryConfig)
}
