import { useMutation } from 'react-query'

import { OrderUpdItem } from '@/api/@types'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export type UpdateOrderItemDTO = {
  orderNo: string
  body: OrderUpdItem
}

export const updateOrderItem = ({ orderNo, body }: UpdateOrderItemDTO) => {
  return aspidaClient.v1.orders._orderNo(orderNo).$patch({ body })
}

type UseDeleteOrderItemOptions = {
  config?: MutationConfig<typeof updateOrderItem>
}

export const useUpdateOrderItem = ({ config }: UseDeleteOrderItemOptions) => {
  return useMutation({
    ...config,
    mutationFn: updateOrderItem,
  })
}
