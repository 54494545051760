import { useMutation } from 'react-query'

import type { Methods } from '@/api/v1/orders'
import { orderHistoryKeyFactory } from '@/hooks/features/orderHistory/key'
import { aspidaClient } from '@/lib/aspida'
import { queryClient } from '@/lib/react-query'
import { MutationConfig } from '@/lib/react-query'

import { orderKeyFactory } from './key'

type CancelOrdersDTO = {
  data: Methods['delete']['reqBody']
}
export const cancelOrders = ({ data }: CancelOrdersDTO) => {
  return aspidaClient.v1.orders.$delete({ body: data })
}

type UseCancelOrdersOptions = {
  config?: MutationConfig<typeof cancelOrders>
}

export const useCancelOrders = ({ config }: UseCancelOrdersOptions) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(orderKeyFactory.all)
      queryClient.invalidateQueries(orderHistoryKeyFactory.all)
    },
    ...config,
    mutationFn: cancelOrders,
  })
}
