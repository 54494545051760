import type { Methods as AvailableDeliveryPeriodMethods } from '@/api/v1/available-delivery-period'
import type { Methods as ListMethods } from '@/api/v1/order-histories'
import type { Methods as DetailByUnshippedConditionsMethods } from '@/api/v1/order-histories/unshipped'
// import type {Methods as OrderHistoryCsvMethods} from "@/api/v1/order-histories/_downloadId/download"

export const orderHistoryKeyFactory = {
  all: [{ scope: 'orderHistory' }] as const,
  orderHistories: ({
    queryParams,
  }: {
    queryParams: ListMethods['get']['query']
  }) =>
    [
      {
        ...orderHistoryKeyFactory.all[0],
        entity: 'orderHistories',
        queryParams,
      },
    ] as const,
  detailBySalesOrderNo: ({
    pathParams,
  }: {
    pathParams: { salesOrderNo: string }
  }) =>
    [
      {
        ...orderHistoryKeyFactory.all[0],
        entity: 'detailBySalesOrderNo',
        pathParams,
      },
    ] as const,
  detailByUnshippedConditions: ({
    queryParams,
  }: {
    queryParams: DetailByUnshippedConditionsMethods['get']['query']
  }) =>
    [
      {
        ...orderHistoryKeyFactory.all[0],
        entity: 'detailByUnshippedConditions',
        queryParams,
      },
    ] as const,
  availableDeliveryPeriod: ({
    queryParams,
  }: {
    queryParams: AvailableDeliveryPeriodMethods['get']['query']
  }) => [
    {
      ...orderHistoryKeyFactory.all[0],
      entity: 'availableDeliveryDatePeriod',
      queryParams,
    },
  ],
  orderHistoriesCsv: ({
    pathParams,
  }: {
    pathParams: { downloadId: string }
  }) => [
    {
      ...orderHistoryKeyFactory.all[0],
      entity: 'orderHistoriesCsv',
      pathParams,
    },
  ],
}
