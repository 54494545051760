import { ErrorOutline } from '@mui/icons-material'
import clsx from 'clsx'
import { useState } from 'react'

import type { AnnouncementCardListProps } from './type'

import {
  AnnouncementCard,
  AnnouncementDetailModal,
} from '@/components/features/announcement'
import { IconWrapper } from '@/components/ui'
import { useModal } from '@/hooks/util'

export const AnnouncementCardList = ({
  announcements,
}: AnnouncementCardListProps) => {
  const { isOpen, open, close } = useModal()

  const [selectedAnnouncement, setSelectedAnnouncement] = useState<
    (typeof announcements)[number] | null
  >(null)

  const handleClickCard = (announcement: (typeof announcements)[number]) => {
    setSelectedAnnouncement(announcement)
    open()
  }

  return (
    <div
      className={clsx(
        'tw-rounded tw-border tw-border-primary-100',
        'tw-overflow-hidden'
      )}
    >
      <div
        className={clsx(
          'tw-flex tw-h-11 tw-items-center tw-gap-1',
          'tw-px-2',
          'tw-rounded-t tw-border-b tw-border-primary-100',
          'tw-bg-primary-50'
        )}
      >
        <IconWrapper>
          <ErrorOutline
            sx={{
              fontSize: 24,
            }}
            className={clsx('tw-text-primary-500')}
          />
        </IconWrapper>
        <p className={clsx('tw-text-sm tw-font-bold tw-text-primary-500')}>
          お知らせ
        </p>
      </div>
      <ul className={clsx('tw-divide-y tw-divide-gray-300 tw-px-2')}>
        {announcements.map((announcement) => (
          <li
            key={`announcement-${announcement.sys.id}`}
            className={clsx(
              'tw-py-2',
              'tw-cursor-pointer tw-transition tw-duration-stateChange hover:tw-opacity-hover'
            )}
            onClick={() => handleClickCard(announcement)}
          >
            <AnnouncementCard
              title={announcement.fields.title}
              targetDate={announcement.fields.announcementDate}
            />
          </li>
        ))}
      </ul>
      {selectedAnnouncement !== null && (
        <AnnouncementDetailModal
          isOpen={isOpen}
          onClose={close}
          {...selectedAnnouncement.fields}
        />
      )}
    </div>
  )
}
