import { useQuery } from 'react-query'

import { contentfulClient } from '@/lib/contentful'
import { CONTENT_TYPES, SORT_KEY } from '@/lib/contentful/const'
import { QueryConfig } from '@/lib/react-query'
import type { IAnnouncementFields } from '@/types/generated/contentful'

import { contentfulAnnouncementKeyFactory as keyFactory } from './key'

type TQueryParams = {
  [key: string]: string
}

export const getContentfulAnnouncements = (queryParams: TQueryParams = {}) => {
  return contentfulClient.getEntries<IAnnouncementFields>({
    content_type: CONTENT_TYPES.announcement,
    order: SORT_KEY.announcement,
    ...queryParams,
  })
}

export type UseContentfulAnnouncementsOptions = {
  config?: QueryConfig<typeof getContentfulAnnouncements>
  queryParams?: TQueryParams
}

export const useContentfulAnnouncements = ({
  config,
  queryParams = {},
}: UseContentfulAnnouncementsOptions) => {
  const queryConfig: QueryConfig<typeof getContentfulAnnouncements> = {
    ...config,
    queryKey: keyFactory.announcements(),
    queryFn: () => getContentfulAnnouncements(queryParams),
  }
  return useQuery(queryConfig)
}
