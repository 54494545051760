import { AccessTime, Search } from '@mui/icons-material'
import clsx from 'clsx'
import { useMemo } from 'react'

import { AnnouncementCardList } from '@/components/features/announcement'
import { FavoriteIcon } from '@/components/icons'
import { PageWithHeader, PageWrapper } from '@/components/layout'
import { IconWrapper, Image, LinkButton, LoadingOverlay } from '@/components/ui'
import {
  FAVORITE_TITLE,
  INVOICE_TITLE,
  ITEM_TITLE,
  ORDER_HISTORY_ACTIONS,
  ORDER_HISTORY_TITLE,
  ORDER_IMPORT_TITLE,
  PURCHASED_INFO_TITLE,
  PURCHASE_TITLE,
  ROUTE,
} from '@/const'
import { useLimitedMode } from '@/hooks/features/auth'
import { useContentfulAnnouncements } from '@/hooks/features/contentfulAnnouncement'
import { useUnapprovedOrders } from '@/hooks/features/order'
import { useMyStore } from '@/hooks/features/store'
import { useMe } from '@/hooks/features/user'
import { formatContentfulDate } from '@/utils/date'

export const TopPage = () => {
  const now = formatContentfulDate(new Date())
  const announcementsQuery = useContentfulAnnouncements({
    queryParams: {
      'fields.publicationStartDate[lte]': now, // 公開開始時間を過ぎている
      'fields.publicationEndDate[gte]': now, // 公開終了時間を過ぎていない
    },
  })
  const unapprovedOrdersQuery = useUnapprovedOrders({})
  const { data: me } = useMe({})
  const { data: myStore } = useMyStore({})
  const { mode, isLimitedTerm } = useLimitedMode()

  const hasAlert = useMemo(() => {
    if (!unapprovedOrdersQuery.data) return false
    const unapprovedOrdersCount =
      unapprovedOrdersQuery.data.orderNos?.length ?? 0
    return unapprovedOrdersCount > 0
  }, [unapprovedOrdersQuery.data])

  const isLoading = useMemo(
    () => announcementsQuery.isLoading || unapprovedOrdersQuery.isLoading,
    [announcementsQuery.isLoading, unapprovedOrdersQuery.isLoading]
  )

  const showOrderHistory = mode === 'normal' || !isLimitedTerm
  const showFavorite = mode === 'normal' || !isLimitedTerm
  const showSearch = mode === 'normal' || !isLimitedTerm

  return (
    <PageWithHeader withSearch={true}>
      <PageWrapper withBreadcrumb={false} spacingX={'sm'}>
        <LoadingOverlay isLoading={isLoading} />

        <div
          className={clsx(
            'tw-p-4 tw-text-center tw-text-[15px]',
            'tw-pt-0',
            !showOrderHistory && !showFavorite && !showSearch && 'tw-pb-2'
          )}
        >
          {`${myStore?.storeName || '-'} 様`}
        </div>

        {announcementsQuery.data !== undefined &&
          announcementsQuery.data.items.length > 0 && (
            <AnnouncementCardList
              announcements={announcementsQuery.data.items}
            />
          )}

        <div
          className={clsx(
            !showOrderHistory && !showFavorite && !showSearch
              ? 'tw-mt-0'
              : 'tw-mt-16',
            'tw-flex tw-flex-col tw-gap-4'
          )}
        >
          <div
            className={clsx(
              !showOrderHistory && !showFavorite && !showSearch
                ? 'tw-mt-0'
                : 'tw-mt-8'
            )}
          >
            {showOrderHistory && (
              <div
                className={clsx(
                  'tw-relative',
                  'after:tw-absolute',
                  'tw-left-0',
                  hasAlert ? 'after:tw-top-[-71px]' : 'after:tw-top-[-73px]',
                  'after:tw-block',
                  "after:tw-content-['']",
                  hasAlert
                    ? 'after:tw-h-[72px] after:tw-w-[110px] after:tw-bg-kikumaAlert'
                    : 'after:tw-h-[75px] after:tw-w-[96px] after:tw-bg-kikumaTel',
                  'after:tw-bg-contain'
                )}
              >
                <LinkButton
                  href={ROUTE.orderHistories}
                  variant={hasAlert ? 'notice' : 'secondary'}
                  size={'xl'}
                >
                  <div className={clsx('tw-flex tw-flex-col tw-items-center')}>
                    <div
                      className={clsx('tw-flex tw-items-center tw-space-x-2')}
                    >
                      <IconWrapper>
                        <AccessTime
                          sx={{
                            fontSize: 24,
                          }}
                          className={clsx('tw-text-primary-500')}
                        />
                      </IconWrapper>
                      <span>{ORDER_HISTORY_TITLE}</span>
                    </div>
                    {hasAlert && (
                      <div
                        className={clsx('tw-mt-1 tw-text-sm tw-text-alert-500')}
                      >{`${ORDER_HISTORY_ACTIONS.APPROVE}が完了していない${ORDER_HISTORY_TITLE}があります`}</div>
                    )}
                  </div>
                </LinkButton>
              </div>
            )}
          </div>
          <div className={clsx('tw-flex tw-items-center tw-gap-2')}>
            {showFavorite && (
              <LinkButton
                href={ROUTE.favorites}
                variant={'secondary'}
                size={'xl'}
              >
                <IconWrapper>
                  <FavoriteIcon size={24} type="empty" />
                </IconWrapper>
                <span>{`${FAVORITE_TITLE}リスト`}</span>
              </LinkButton>
            )}
            {showSearch && (
              <LinkButton href={ROUTE.search} variant={'secondary'} size={'xl'}>
                <IconWrapper>
                  <Search
                    sx={{
                      fontSize: 24,
                    }}
                    className={clsx('tw-text-primary-500')}
                  />
                </IconWrapper>
                <span>{`${ITEM_TITLE}検索`}</span>
              </LinkButton>
            )}
          </div>
          {!!myStore?.importOrder && (
            <div className={clsx('tw-flex tw-items-center tw-gap-2')}>
              <LinkButton
                href={ROUTE.orderImport}
                variant={'secondary'}
                size={'lg'}
              >
                {ORDER_IMPORT_TITLE}
              </LinkButton>
              <LinkButton
                href={ROUTE.purchases}
                variant={'secondary'}
                size={'lg'}
              >
                {PURCHASE_TITLE}
              </LinkButton>
            </div>
          )}
          {!!me?.uploadOrder && (
            <div>
              <LinkButton
                href={ROUTE.purchasedInfo}
                variant={'secondary'}
                size={'lg'}
              >
                {PURCHASED_INFO_TITLE}
              </LinkButton>
            </div>
          )}
          {!!me?.invoice && (
            <div>
              <LinkButton
                href={ROUTE.invoice}
                variant={'secondary'}
                size={'lg'}
              >
                {INVOICE_TITLE}
              </LinkButton>
            </div>
          )}
        </div>
        <div className={clsx('tw-mt-1', 'tw-flex tw-justify-end')}>
          <div className={clsx('tw-h-[169px] tw-w-[124px]')}>
            <Image src="/images/kikumi_cart.png" alt="" isEventNone={true} />
          </div>
        </div>
      </PageWrapper>
    </PageWithHeader>
  )
}
