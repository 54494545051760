import { useMutation } from 'react-query'

import type { Order } from '@/api/@types'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export const cancelOrder = (orderNo: Order['orderNo']) => {
  return aspidaClient.v1.orders._orderNo(orderNo).$delete()
}

type UseCancelOrderOptions = {
  config?: MutationConfig<typeof cancelOrder>
}

export const useCancelOrder = ({ config }: UseCancelOrderOptions) => {
  return useMutation({
    ...config,
    mutationFn: cancelOrder,
  })
}
