import { IAnnouncement } from '@/types/generated/contentful'

export const contentfulAnnouncementKeyFactory = {
  all: [{ scope: 'contentfulAnnouncementKey' }] as const,
  announcements: () =>
    [
      { ...contentfulAnnouncementKeyFactory.all[0], entity: 'announcements' },
    ] as const,
}

export const contentfulPreviewAnnouncementKeyFactory = {
  all: [{ scope: 'contentfulPreviewAnnouncementKey' }] as const,
  announcement: ({ id }: { id: IAnnouncement['sys']['id'] }) =>
    [
      {
        ...contentfulPreviewAnnouncementKeyFactory.all[0],
        entity: 'announcements',
        id,
      },
    ] as const,
}
