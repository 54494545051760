import {} from 'react'
import clsx from 'clsx'

import type { AnnouncementCardProps } from './type'

export const AnnouncementCard = ({
  title,
  targetDate,
}: AnnouncementCardProps) => {
  return (
    <div className={clsx('tw-text-sm')}>
      <div className={clsx('tw-text-gray-600')}>{targetDate}</div>
      <div className={clsx('tw-font-bold')}>{title}</div>
      <div className={clsx('tw-text-primary-500 tw-underline')}>詳細を見る</div>
    </div>
  )
}
