import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { orderKeyFactory } from './key'

const getUnapprovedOrders = () => {
  return aspidaClient.v1.orders.unapproved.$get()
}

export type UseUnapprovedOrdersOptions = {
  config?: QueryConfig<typeof getUnapprovedOrders>
}

export const useUnapprovedOrders = ({ config }: UseUnapprovedOrdersOptions) => {
  const queryConfig: QueryConfig<typeof getUnapprovedOrders> = {
    ...config,
    queryKey: orderKeyFactory.unapprovedList(),
    queryFn: () => getUnapprovedOrders(),
  }
  return useQuery(queryConfig)
}
