import { useMutation } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export type DeleteOrderItemDTO = {
  orderNo: string
  itemCode: string
}

export const deleteOrderItem = ({ orderNo, itemCode }: DeleteOrderItemDTO) => {
  return aspidaClient.v1.orders
    ._orderNo(orderNo)
    .items._itemCode(itemCode)
    .$delete()
}

type UseDeleteOrderItemOptions = {
  config?: MutationConfig<typeof deleteOrderItem>
}

export const useDeleteOrderItem = ({ config }: UseDeleteOrderItemOptions) => {
  return useMutation({
    ...config,
    mutationFn: deleteOrderItem,
  })
}
