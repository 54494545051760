import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/orders/approve'
import { orderKeyFactory } from '@/hooks/features/order/key'
import { orderHistoryKeyFactory } from '@/hooks/features/orderHistory/key'
import { aspidaClient } from '@/lib/aspida'
import { queryClient } from '@/lib/react-query'
import { MutationConfig } from '@/lib/react-query'

export type RevokeOrdersApprovalDTO = {
  data: Methods['delete']['reqBody']
}

export const revokeOrdersApproval = ({ data }: RevokeOrdersApprovalDTO) => {
  return aspidaClient.v1.orders.approve.$delete({ body: data })
}

type UseRevokeOrdersApprovalOptions = {
  config?: MutationConfig<typeof revokeOrdersApproval>
  invalidateTarget: 'order' | 'orderHistory'
}

export const useRevokeOrdersApproval = ({
  config,
  invalidateTarget,
}: UseRevokeOrdersApprovalOptions) => {
  return useMutation({
    onSuccess: () =>
      queryClient.invalidateQueries(
        invalidateTarget === 'order'
          ? orderKeyFactory.all
          : orderHistoryKeyFactory.all
      ),
    ...config,
    mutationFn: revokeOrdersApproval,
  })
}
